import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const HomeIcon = ({
  width = '24px',
  height = '24px',
  fillColor,
}: IconProps) => {
  const theme = useTheme();
  if (!fillColor) {
    fillColor = theme.colors.primary[500];
  }
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18.5H9V13.5C9 13.2167 9.09583 12.9792 9.2875 12.7875C9.47917 12.5958 9.71667 12.5 10 12.5H14C14.2833 12.5 14.5208 12.5958 14.7125 12.7875C14.9042 12.9792 15 13.2167 15 13.5V18.5H18V9.5L12 5L6 9.5V18.5ZM4 18.5V9.5C4 9.18333 4.07083 8.88333 4.2125 8.6C4.35417 8.31667 4.55 8.08333 4.8 7.9L10.8 3.4C11.15 3.13333 11.55 3 12 3C12.45 3 12.85 3.13333 13.2 3.4L19.2 7.9C19.45 8.08333 19.6458 8.31667 19.7875 8.6C19.9292 8.88333 20 9.18333 20 9.5V18.5C20 19.05 19.8042 19.5208 19.4125 19.9125C19.0208 20.3042 18.55 20.5 18 20.5H14C13.7167 20.5 13.4792 20.4042 13.2875 20.2125C13.0958 20.0208 13 19.7833 13 19.5V14.5H11V19.5C11 19.7833 10.9042 20.0208 10.7125 20.2125C10.5208 20.4042 10.2833 20.5 10 20.5H6C5.45 20.5 4.97917 20.3042 4.5875 19.9125C4.19583 19.5208 4 19.05 4 18.5Z"
        fill={fillColor}
      />
    </Svg>
  );
};
