import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const FilterIcon = ({
  height = '24px',
  width = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="filter-icon"
    >
      <g clipPath="url(#clip0_24045_3092)">
        <path
          d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_24045_3092">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
