import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const SecurityIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 18C17.9167 18 18.2708 17.8542 18.5625 17.5625C18.8542 17.2708 19 16.9167 19 16.5C19 16.0833 18.8542 15.7292 18.5625 15.4375C18.2708 15.1458 17.9167 15 17.5 15C17.0833 15 16.7292 15.1458 16.4375 15.4375C16.1458 15.7292 16 16.0833 16 16.5C16 16.9167 16.1458 17.2708 16.4375 17.5625C16.7292 17.8542 17.0833 18 17.5 18ZM17.5 21C18 21 18.4667 20.8833 18.9 20.65C19.3333 20.4167 19.6917 20.0917 19.975 19.675C19.5917 19.4417 19.1917 19.2708 18.775 19.1625C18.3583 19.0542 17.9333 19 17.5 19C17.0667 19 16.6417 19.0542 16.225 19.1625C15.8083 19.2708 15.4083 19.4417 15.025 19.675C15.3083 20.0917 15.6667 20.4167 16.1 20.65C16.5333 20.8833 17 21 17.5 21ZM8.5 8H14.5V6C14.5 5.16667 14.2083 4.45833 13.625 3.875C13.0417 3.29167 12.3333 3 11.5 3C10.6667 3 9.95833 3.29167 9.375 3.875C8.79167 4.45833 8.5 5.16667 8.5 6V8ZM11.75 22H5.5C4.95 22 4.47917 21.8042 4.0875 21.4125C3.69583 21.0208 3.5 20.55 3.5 20V10C3.5 9.45 3.69583 8.97917 4.0875 8.5875C4.47917 8.19583 4.95 8 5.5 8H6.5V6C6.5 4.61667 6.9875 3.4375 7.9625 2.4625C8.9375 1.4875 10.1167 1 11.5 1C12.8833 1 14.0625 1.4875 15.0375 2.4625C16.0125 3.4375 16.5 4.61667 16.5 6V8H17.5C18.05 8 18.5208 8.19583 18.9125 8.5875C19.3042 8.97917 19.5 9.45 19.5 10V11.3C19.2 11.2 18.8875 11.125 18.5625 11.075C18.2375 11.025 17.8833 11 17.5 11V10H5.5V20H10.8C10.9333 20.4 11.0667 20.7458 11.2 21.0375C11.3333 21.3292 11.5167 21.65 11.75 22ZM17.5 23C16.1167 23 14.9375 22.5125 13.9625 21.5375C12.9875 20.5625 12.5 19.3833 12.5 18C12.5 16.6167 12.9875 15.4375 13.9625 14.4625C14.9375 13.4875 16.1167 13 17.5 13C18.8833 13 20.0625 13.4875 21.0375 14.4625C22.0125 15.4375 22.5 16.6167 22.5 18C22.5 19.3833 22.0125 20.5625 21.0375 21.5375C20.0625 22.5125 18.8833 23 17.5 23Z"
        fill={fillColor}
      />
    </Svg>
  );
};
