import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const TableChartIcon = ({
  width = '48px',
  height = '48px',
  fillColor,
}: IconProps) => {
  const theme = useTheme();
  if (!fillColor) {
    fillColor = theme.colors.primary[500];
  }
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >
      <g clip-path="url(#clip0_6132_4088)">
        <path
          d="M40 6H10C7.8 6 6 7.8 6 10V38C6 40.2 7.8 42 10 42H40C42.2 42 44 40.2 44 38V10C44 7.8 42.2 6 40 6ZM40 10V16H10V10H40ZM30 38H20V20H30V38ZM10 20H16V38H10V20ZM34 38V20H40V38H34Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_6132_4088">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
