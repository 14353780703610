import { Svg } from '../styles';
import { VariantType } from './types';

interface StarProps {
  width?: string;
  height?: string;
  variant?: VariantType;
  isActive?: boolean;
  fillColor?: string;
}

export const StarIcon = ({
  width = '24',
  height = '24',
  isActive,
  variant,
  fillColor,
}: StarProps) => {
  if (isActive) {
    switch (variant) {
      case 'yellow':
        return (
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 17.2419L7.63747 19.8408C7.44474 19.9621 7.24326 20.0141 7.03302 19.9968C6.82278 19.9794 6.63881 19.9101 6.48113 19.7888C6.32345 19.6676 6.20081 19.516 6.11321 19.334C6.02561 19.1521 6.00809 18.9485 6.06065 18.7233L7.21698 13.8114L3.35377 10.5108C3.17857 10.3549 3.06907 10.1773 3.02527 9.97802C2.98147 9.77878 2.99461 9.58386 3.06469 9.39327C3.13477 9.20269 3.23989 9.04675 3.38005 8.92547C3.52022 8.80419 3.71294 8.72622 3.95822 8.69157L9.0566 8.24976L11.0276 3.62373C11.1152 3.41582 11.251 3.25989 11.435 3.15593C11.6189 3.05198 11.8073 3 12 3C12.1927 3 12.3811 3.05198 12.565 3.15593C12.749 3.25989 12.8848 3.41582 12.9724 3.62373L14.9434 8.24976L20.0418 8.69157C20.2871 8.72622 20.4798 8.80419 20.6199 8.92547C20.7601 9.04675 20.8652 9.20269 20.9353 9.39327C21.0054 9.58386 21.0185 9.77878 20.9747 9.97802C20.9309 10.1773 20.8214 10.3549 20.6462 10.5108L16.783 13.8114L17.9394 18.7233C17.9919 18.9485 17.9744 19.1521 17.8868 19.334C17.7992 19.516 17.6765 19.6676 17.5189 19.7888C17.3612 19.9101 17.1772 19.9794 16.967 19.9968C16.7567 20.0141 16.5553 19.9621 16.3625 19.8408L12 17.2419Z"
              fill="url(#paint0_linear_4591_15309)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_4591_15309"
                x1="3"
                y1="3"
                x2="19.9723"
                y2="20.9706"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFDE30" />
                <stop offset="1" stop-color="#EA8E03" />
              </linearGradient>
            </defs>
          </Svg>
        );
      case 'blue':
        return (
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 17.2419L7.63747 19.8408C7.44474 19.9621 7.24326 20.0141 7.03302 19.9968C6.82278 19.9794 6.63881 19.9101 6.48113 19.7888C6.32345 19.6676 6.20081 19.516 6.11321 19.334C6.02561 19.1521 6.00809 18.9485 6.06065 18.7233L7.21698 13.8114L3.35377 10.5108C3.17857 10.3549 3.06907 10.1773 3.02527 9.97802C2.98147 9.77878 2.99461 9.58386 3.06469 9.39327C3.13477 9.20269 3.23989 9.04675 3.38005 8.92547C3.52022 8.80419 3.71294 8.72622 3.95822 8.69157L9.0566 8.24976L11.0276 3.62373C11.1152 3.41582 11.251 3.25989 11.435 3.15593C11.6189 3.05198 11.8073 3 12 3C12.1927 3 12.3811 3.05198 12.565 3.15593C12.749 3.25989 12.8848 3.41582 12.9724 3.62373L14.9434 8.24976L20.0418 8.69157C20.2871 8.72622 20.4798 8.80419 20.6199 8.92547C20.7601 9.04675 20.8652 9.20269 20.9353 9.39327C21.0054 9.58386 21.0185 9.77878 20.9747 9.97802C20.9309 10.1773 20.8214 10.3549 20.6462 10.5108L16.783 13.8114L17.9394 18.7233C17.9919 18.9485 17.9744 19.1521 17.8868 19.334C17.7992 19.516 17.6765 19.6676 17.5189 19.7888C17.3612 19.9101 17.1772 19.9794 16.967 19.9968C16.7567 20.0141 16.5553 19.9621 16.3625 19.8408L12 17.2419Z"
              fill="url(#paint0_linear_5745_23780)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5745_23780"
                x1="3"
                y1="3"
                x2="19.9723"
                y2="20.9706"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#20F8F8" />
                <stop offset="1" stop-color="#017AE9" />
              </linearGradient>
            </defs>
          </Svg>
        );
      case 'black':
        return (
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 17.2419L7.63747 19.8408C7.44474 19.9621 7.24326 20.0141 7.03302 19.9968C6.82278 19.9794 6.63881 19.9101 6.48113 19.7888C6.32345 19.6676 6.20081 19.516 6.11321 19.334C6.02561 19.1521 6.00809 18.9485 6.06065 18.7233L7.21698 13.8114L3.35377 10.5108C3.17857 10.3549 3.06907 10.1773 3.02527 9.97802C2.98147 9.77878 2.99461 9.58386 3.06469 9.39327C3.13477 9.20269 3.23989 9.04675 3.38005 8.92547C3.52022 8.80419 3.71294 8.72622 3.95822 8.69157L9.0566 8.24976L11.0276 3.62373C11.1152 3.41582 11.251 3.25989 11.435 3.15593C11.6189 3.05198 11.8073 3 12 3C12.1927 3 12.3811 3.05198 12.565 3.15593C12.749 3.25989 12.8848 3.41582 12.9724 3.62373L14.9434 8.24976L20.0418 8.69157C20.2871 8.72622 20.4798 8.80419 20.6199 8.92547C20.7601 9.04675 20.8652 9.20269 20.9353 9.39327C21.0054 9.58386 21.0185 9.77878 20.9747 9.97802C20.9309 10.1773 20.8214 10.3549 20.6462 10.5108L16.783 13.8114L17.9394 18.7233C17.9919 18.9485 17.9744 19.1521 17.8868 19.334C17.7992 19.516 17.6765 19.6676 17.5189 19.7888C17.3612 19.9101 17.1772 19.9794 16.967 19.9968C16.7567 20.0141 16.5553 19.9621 16.3625 19.8408L12 17.2419Z"
              fill="url(#paint0_linear_5745_23788)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5745_23788"
                x1="3"
                y1="3"
                x2="19.9723"
                y2="20.9706"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#AAAAAA" />
                <stop offset="1" stop-color="#222222" />
              </linearGradient>
            </defs>
          </Svg>
        );
      default:
        return (
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 17.2419L7.63747 19.8408C7.44474 19.9621 7.24326 20.0141 7.03302 19.9968C6.82278 19.9794 6.63881 19.9101 6.48113 19.7888C6.32345 19.6676 6.20081 19.516 6.11321 19.334C6.02561 19.1521 6.00809 18.9485 6.06065 18.7233L7.21698 13.8114L3.35377 10.5108C3.17857 10.3549 3.06907 10.1773 3.02527 9.97802C2.98147 9.77878 2.99461 9.58386 3.06469 9.39327C3.13477 9.20269 3.23989 9.04675 3.38005 8.92547C3.52022 8.80419 3.71294 8.72622 3.95822 8.69157L9.0566 8.24976L11.0276 3.62373C11.1152 3.41582 11.251 3.25989 11.435 3.15593C11.6189 3.05198 11.8073 3 12 3C12.1927 3 12.3811 3.05198 12.565 3.15593C12.749 3.25989 12.8848 3.41582 12.9724 3.62373L14.9434 8.24976L20.0418 8.69157C20.2871 8.72622 20.4798 8.80419 20.6199 8.92547C20.7601 9.04675 20.8652 9.20269 20.9353 9.39327C21.0054 9.58386 21.0185 9.77878 20.9747 9.97802C20.9309 10.1773 20.8214 10.3549 20.6462 10.5108L16.783 13.8114L17.9394 18.7233C17.9919 18.9485 17.9744 19.1521 17.8868 19.334C17.7992 19.516 17.6765 19.6676 17.5189 19.7888C17.3612 19.9101 17.1772 19.9794 16.967 19.9968C16.7567 20.0141 16.5553 19.9621 16.3625 19.8408L12 17.2419Z"
              fill="url(#paint0_linear_5745_23788)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5745_23788"
                x1="3"
                y1="3"
                x2="19.9723"
                y2="20.9706"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#AAAAAA" />
                <stop offset="1" stop-color="#222222" />
              </linearGradient>
            </defs>
          </Svg>
        );
    }
  } else {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M8.68868 16.7741L12 14.799L15.3113 16.8001L14.4441 13.0577L17.3612 10.5628L13.5243 10.2249L12 6.69043L10.4757 10.1989L6.63881 10.5368L9.55593 13.0577L8.68868 16.7741ZM12 17.2419L7.63747 19.8408C7.44474 19.9621 7.24326 20.0141 7.03302 19.9968C6.82278 19.9794 6.63881 19.9101 6.48113 19.7888C6.32345 19.6676 6.20081 19.516 6.11321 19.334C6.02561 19.1521 6.00809 18.9485 6.06065 18.7233L7.21698 13.8114L3.35377 10.5108C3.17857 10.3549 3.06907 10.1773 3.02527 9.97802C2.98147 9.77878 2.99461 9.58386 3.06469 9.39327C3.13477 9.20269 3.23989 9.04675 3.38005 8.92547C3.52022 8.80419 3.71294 8.72622 3.95822 8.69157L9.0566 8.24976L11.0276 3.62373C11.1152 3.41582 11.251 3.25989 11.435 3.15593C11.6189 3.05198 11.8073 3 12 3C12.1927 3 12.3811 3.05198 12.565 3.15593C12.749 3.25989 12.8848 3.41582 12.9724 3.62373L14.9434 8.24976L20.0418 8.69157C20.2871 8.72622 20.4798 8.80419 20.6199 8.92547C20.7601 9.04675 20.8652 9.20269 20.9353 9.39327C21.0054 9.58386 21.0185 9.77878 20.9747 9.97802C20.9309 10.1773 20.8214 10.3549 20.6462 10.5108L16.783 13.8114L17.9394 18.7233C17.9919 18.9485 17.9744 19.1521 17.8868 19.334C17.7992 19.516 17.6765 19.6676 17.5189 19.7888C17.3612 19.9101 17.1772 19.9794 16.967 19.9968C16.7567 20.0141 16.5553 19.9621 16.3625 19.8408L12 17.2419Z"
          fill={fillColor}
        />
      </Svg>
    );
  }
};
