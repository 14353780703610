import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const AppsIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="7"
        height="7"
        rx="1"
        stroke={fillColor}
        stroke-width="2"
      />
      <rect
        x="11"
        y="1"
        width="6"
        height="6"
        rx="1"
        stroke={fillColor}
        stroke-width="2"
      />
      <rect
        x="10"
        y="10"
        width="7"
        height="7"
        rx="1"
        stroke={fillColor}
        stroke-width="2"
      />
      <rect
        x="1"
        y="11"
        width="6"
        height="6"
        rx="1"
        stroke={fillColor}
        stroke-width="2"
      />
    </Svg>
  );
};
