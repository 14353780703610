import theme from 'stories/theme';

import { IconProps } from '..';
import { Svg } from '../styles';

export const DotIcon = ({
  height = '24',
  width = '24',
  fillColor = theme.colors.primary[500],
}: IconProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle cx="8" cy="8" r="3" fill={fillColor} />
  </Svg>
);
