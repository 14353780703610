import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const InfoIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99992 11.333C8.18881 11.333 8.34714 11.2691 8.47492 11.1413C8.6027 11.0136 8.66659 10.8552 8.66659 10.6663V7.99967C8.66659 7.81079 8.6027 7.65245 8.47492 7.52467C8.34714 7.3969 8.18881 7.33301 7.99992 7.33301C7.81103 7.33301 7.6527 7.3969 7.52492 7.52467C7.39714 7.65245 7.33325 7.81079 7.33325 7.99967V10.6663C7.33325 10.8552 7.39714 11.0136 7.52492 11.1413C7.6527 11.2691 7.81103 11.333 7.99992 11.333ZM7.99992 5.99967C8.18881 5.99967 8.34714 5.93579 8.47492 5.80801C8.6027 5.68023 8.66659 5.5219 8.66659 5.33301C8.66659 5.14412 8.6027 4.98579 8.47492 4.85801C8.34714 4.73023 8.18881 4.66634 7.99992 4.66634C7.81103 4.66634 7.6527 4.73023 7.52492 4.85801C7.39714 4.98579 7.33325 5.14412 7.33325 5.33301C7.33325 5.5219 7.39714 5.68023 7.52492 5.80801C7.6527 5.93579 7.81103 5.99967 7.99992 5.99967ZM7.99992 14.6663C7.0777 14.6663 6.21103 14.4913 5.39992 14.1413C4.58881 13.7913 3.88325 13.3163 3.28325 12.7163C2.68325 12.1163 2.20825 11.4108 1.85825 10.5997C1.50825 9.78856 1.33325 8.9219 1.33325 7.99967C1.33325 7.07745 1.50825 6.21079 1.85825 5.39967C2.20825 4.58856 2.68325 3.88301 3.28325 3.28301C3.88325 2.68301 4.58881 2.20801 5.39992 1.85801C6.21103 1.50801 7.0777 1.33301 7.99992 1.33301C8.92214 1.33301 9.78881 1.50801 10.5999 1.85801C11.411 2.20801 12.1166 2.68301 12.7166 3.28301C13.3166 3.88301 13.7916 4.58856 14.1416 5.39967C14.4916 6.21079 14.6666 7.07745 14.6666 7.99967C14.6666 8.9219 14.4916 9.78856 14.1416 10.5997C13.7916 11.4108 13.3166 12.1163 12.7166 12.7163C12.1166 13.3163 11.411 13.7913 10.5999 14.1413C9.78881 14.4913 8.92214 14.6663 7.99992 14.6663ZM7.99992 13.333C9.48881 13.333 10.7499 12.8163 11.7833 11.783C12.8166 10.7497 13.3333 9.48856 13.3333 7.99967C13.3333 6.51079 12.8166 5.24967 11.7833 4.21634C10.7499 3.18301 9.48881 2.66634 7.99992 2.66634C6.51103 2.66634 5.24992 3.18301 4.21659 4.21634C3.18325 5.24967 2.66659 6.51079 2.66659 7.99967C2.66659 9.48856 3.18325 10.7497 4.21659 11.783C5.24992 12.8163 6.51103 13.333 7.99992 13.333Z"
        fill={fillColor}
      />
    </Svg>
  );
};
