import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const OnboardingIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 18C11.0667 18 10.175 18.1458 9.325 18.4375C8.475 18.7292 7.7 19.1667 7 19.75V20H17V19.75C16.3 19.1667 15.525 18.7292 14.675 18.4375C13.825 18.1458 12.9333 18 12 18ZM12 16C13.15 16 14.225 16.175 15.225 16.525C16.225 16.875 17.15 17.3667 18 18V4H6V18C6.85 17.3667 7.775 16.875 8.775 16.525C9.775 16.175 10.85 16 12 16ZM12 12C11.5833 12 11.2292 11.8542 10.9375 11.5625C10.6458 11.2708 10.5 10.9167 10.5 10.5C10.5 10.0833 10.6458 9.72917 10.9375 9.4375C11.2292 9.14583 11.5833 9 12 9C12.4167 9 12.7708 9.14583 13.0625 9.4375C13.3542 9.72917 13.5 10.0833 13.5 10.5C13.5 10.9167 13.3542 11.2708 13.0625 11.5625C12.7708 11.8542 12.4167 12 12 12ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM12 14C12.9667 14 13.7917 13.6583 14.475 12.975C15.1583 12.2917 15.5 11.4667 15.5 10.5C15.5 9.53333 15.1583 8.70833 14.475 8.025C13.7917 7.34167 12.9667 7 12 7C11.0333 7 10.2083 7.34167 9.525 8.025C8.84167 8.70833 8.5 9.53333 8.5 10.5C8.5 11.4667 8.84167 12.2917 9.525 12.975C10.2083 13.6583 11.0333 14 12 14Z"
        fill={fillColor}
      />
    </Svg>
  );
};
