import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const AppIcon = ({
  width = '1.5rem',
  height = '1.5rem',
  strokeColor,
}: IconProps) => {
  const theme = useTheme();
  if (!strokeColor) {
    strokeColor = theme.colors.primary[500];
  }
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.5"
        y="4"
        width="16"
        height="16"
        rx="3"
        stroke={strokeColor}
        stroke-width="2"
      />
    </Svg>
  );
};
