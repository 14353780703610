import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const InvestmentsIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.45 20.9527L21.4 19.1027C21.3167 18.9527 21.1958 18.8236 21.0375 18.7152C20.8792 18.6069 20.7 18.5527 20.5 18.5527H15.45C15 18.5527 14.6417 18.5361 14.375 18.5027C14.1083 18.4694 13.8333 18.4027 13.55 18.3027L12.125 17.8277C11.8583 17.7444 11.6667 17.5777 11.55 17.3277C11.4333 17.0777 11.4167 16.8194 11.5 16.5527C11.5833 16.2861 11.7458 16.0902 11.9875 15.9652C12.2292 15.8402 12.4833 15.8194 12.75 15.9027L13.8 16.2527C14.0833 16.3361 14.4042 16.4027 14.7625 16.4527C15.1208 16.5027 15.6083 16.5361 16.225 16.5527H16.5C16.5 16.3694 16.4458 16.1944 16.3375 16.0277C16.2292 15.8611 16.1 15.7527 15.95 15.7027L10.1 13.5527H8.5V19.0527L15.45 20.9527ZM14.925 22.9027L8.5 21.1027C8.36667 21.5361 8.10417 21.8861 7.7125 22.1527C7.32083 22.4194 6.91667 22.5527 6.5 22.5527H4.5C3.95 22.5527 3.47917 22.3569 3.0875 21.9652C2.69583 21.5736 2.5 21.1027 2.5 20.5527V13.5527C2.5 13.0027 2.69583 12.5319 3.0875 12.1402C3.47917 11.7486 3.95 11.5527 4.5 11.5527H10.1C10.2167 11.5527 10.3333 11.5652 10.45 11.5902C10.5667 11.6152 10.675 11.6444 10.775 11.6777L16.65 13.8527C17.2 14.0527 17.6458 14.4027 17.9875 14.9027C18.3292 15.4027 18.5 15.9527 18.5 16.5527H20.5C21.3333 16.5527 22.0417 16.8277 22.625 17.3777C23.2083 17.9277 23.5 18.6527 23.5 19.5527C23.5 19.9194 23.4042 20.2069 23.2125 20.4152C23.0208 20.6236 22.725 20.7944 22.325 20.9277L16.075 22.8777C15.8917 22.9444 15.7 22.9777 15.5 22.9777C15.3 22.9777 15.1083 22.9527 14.925 22.9027ZM4.5 20.5527H6.5V13.5527H4.5V20.5527Z"
        fill={fillColor}
      />
      <path
        d="M16.1468 11.0833H14.861C14.6182 11.0833 14.4146 10.9955 14.2503 10.8198C14.086 10.6441 14.0039 10.4264 14.0039 10.1667C14.0039 9.90694 14.086 9.68924 14.2503 9.51354C14.4146 9.33785 14.6182 9.25 14.861 9.25H18.2896V7.41667H14.861C14.6182 7.41667 14.4146 7.32882 14.2503 7.15313C14.086 6.97743 14.0039 6.75972 14.0039 6.5V2.83333C14.0039 2.57361 14.086 2.3559 14.2503 2.18021C14.4146 2.00451 14.6182 1.91667 14.861 1.91667H16.1468C16.1468 1.65694 16.2289 1.43924 16.3932 1.26354C16.5575 1.08785 16.761 1 17.0039 1C17.2468 1 17.4503 1.08785 17.6146 1.26354C17.7789 1.43924 17.861 1.65694 17.861 1.91667H19.1468C19.3896 1.91667 19.5932 2.00451 19.7575 2.18021C19.9218 2.3559 20.0039 2.57361 20.0039 2.83333C20.0039 3.09306 19.9218 3.31076 19.7575 3.48646C19.5932 3.66215 19.3896 3.75 19.1468 3.75H15.7182V5.58333H19.1468C19.3896 5.58333 19.5932 5.67118 19.7575 5.84688C19.9218 6.02257 20.0039 6.24028 20.0039 6.5V10.1667C20.0039 10.4264 19.9218 10.6441 19.7575 10.8198C19.5932 10.9955 19.3896 11.0833 19.1468 11.0833H17.861C17.861 11.3431 17.7789 11.5608 17.6146 11.7365C17.4503 11.9122 17.2468 12 17.0039 12C16.761 12 16.5575 11.9122 16.3932 11.7365C16.2289 11.5608 16.1468 11.3431 16.1468 11.0833Z"
        fill={fillColor}
      />
    </Svg>
  );
};
