import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const LockIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 33 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5 14H26.5V10C26.5 4.48 22.02 0 16.5 0C10.98 0 6.5 4.48 6.5 10V14H4.5C2.3 14 0.5 15.8 0.5 18V38C0.5 40.2 2.3 42 4.5 42H28.5C30.7 42 32.5 40.2 32.5 38V18C32.5 15.8 30.7 14 28.5 14ZM10.5 10C10.5 6.68 13.18 4 16.5 4C19.82 4 22.5 6.68 22.5 10V14H10.5V10ZM28.5 38H4.5V18H28.5V38ZM16.5 32C18.7 32 20.5 30.2 20.5 28C20.5 25.8 18.7 24 16.5 24C14.3 24 12.5 25.8 12.5 28C12.5 30.2 14.3 32 16.5 32Z"
        fill={fillColor}
      />
    </Svg>
  );
};
