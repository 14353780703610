import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const ScheduleIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_31897_291816"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill={fillColor} />
      </mask>
      <g mask="url(#mask0_31897_291816)">
        <path
          d="M8.66732 7.73203V5.33203C8.66732 5.14314 8.60343 4.98481 8.47565 4.85703C8.34787 4.72925 8.18954 4.66536 8.00065 4.66536C7.81176 4.66536 7.65343 4.72925 7.52565 4.85703C7.39787 4.98481 7.33398 5.14314 7.33398 5.33203V7.98203C7.33398 8.07092 7.35065 8.15703 7.38398 8.24036C7.41732 8.3237 7.46732 8.3987 7.53398 8.46536L9.73398 10.6654C9.85621 10.7876 10.0118 10.8487 10.2007 10.8487C10.3895 10.8487 10.5451 10.7876 10.6673 10.6654C10.7895 10.5431 10.8507 10.3876 10.8507 10.1987C10.8507 10.0098 10.7895 9.85425 10.6673 9.73203L8.66732 7.73203ZM8.00065 14.6654C7.07843 14.6654 6.21176 14.4904 5.40065 14.1404C4.58954 13.7904 3.88398 13.3154 3.28398 12.7154C2.68398 12.1154 2.20898 11.4098 1.85898 10.5987C1.50898 9.78759 1.33398 8.92092 1.33398 7.9987C1.33398 7.07648 1.50898 6.20981 1.85898 5.3987C2.20898 4.58759 2.68398 3.88203 3.28398 3.28203C3.88398 2.68203 4.58954 2.20703 5.40065 1.85703C6.21176 1.50703 7.07843 1.33203 8.00065 1.33203C8.92287 1.33203 9.78954 1.50703 10.6007 1.85703C11.4118 2.20703 12.1173 2.68203 12.7173 3.28203C13.3173 3.88203 13.7923 4.58759 14.1423 5.3987C14.4923 6.20981 14.6673 7.07648 14.6673 7.9987C14.6673 8.92092 14.4923 9.78759 14.1423 10.5987C13.7923 11.4098 13.3173 12.1154 12.7173 12.7154C12.1173 13.3154 11.4118 13.7904 10.6007 14.1404C9.78954 14.4904 8.92287 14.6654 8.00065 14.6654ZM8.00065 13.332C9.47843 13.332 10.7368 12.8126 11.7757 11.7737C12.8145 10.7348 13.334 9.47647 13.334 7.9987C13.334 6.52092 12.8145 5.26259 11.7757 4.2237C10.7368 3.18481 9.47843 2.66536 8.00065 2.66536C6.52287 2.66536 5.26454 3.18481 4.22565 4.2237C3.18676 5.26259 2.66732 6.52092 2.66732 7.9987C2.66732 9.47647 3.18676 10.7348 4.22565 11.7737C5.26454 12.8126 6.52287 13.332 8.00065 13.332Z"
          fill={fillColor}
        />
      </g>
    </Svg>
  );
};
