import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';
import { VariantType } from './types';

interface DocumentIconProps {
  variant?: VariantType;
}

export const DocumentIcon = ({
  height = '24',
  width = '24',
  fillColor,
  variant = 'generic',
}: IconProps & DocumentIconProps) => {
  const theme = useTheme();
  if (!fillColor) {
    fillColor = theme.colors.primary[500];
  }
  switch (variant) {
    case 'generic':
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 8V4H6V20H18V9H14C13.7167 9 13.4792 8.90417 13.2875 8.7125C13.0958 8.52083 13 8.28333 13 8Z"
            fill={fillColor}
          />
        </Svg>
      );
    case 'text':
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18H15C15.2833 18 15.5208 17.9042 15.7125 17.7125C15.9042 17.5208 16 17.2833 16 17C16 16.7167 15.9042 16.4792 15.7125 16.2875C15.5208 16.0958 15.2833 16 15 16H9C8.71667 16 8.47917 16.0958 8.2875 16.2875C8.09583 16.4792 8 16.7167 8 17C8 17.2833 8.09583 17.5208 8.2875 17.7125C8.47917 17.9042 8.71667 18 9 18ZM9 14H15C15.2833 14 15.5208 13.9042 15.7125 13.7125C15.9042 13.5208 16 13.2833 16 13C16 12.7167 15.9042 12.4792 15.7125 12.2875C15.5208 12.0958 15.2833 12 15 12H9C8.71667 12 8.47917 12.0958 8.2875 12.2875C8.09583 12.4792 8 12.7167 8 13C8 13.2833 8.09583 13.5208 8.2875 13.7125C8.47917 13.9042 8.71667 14 9 14ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 8V4H6V20H18V9H14C13.7167 9 13.4792 8.90417 13.2875 8.7125C13.0958 8.52083 13 8.28333 13 8Z"
            fill={fillColor}
          />
        </Svg>
      );
    case 'pdf':
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.5875 21.4125C4.97917 21.8042 5.45 22 6 22H18C18.55 22 19.0208 21.8042 19.4125 21.4125C19.8042 21.0208 20 20.55 20 20V8.825C20 8.55833 19.95 8.30417 19.85 8.0625C19.75 7.82083 19.6083 7.60833 19.425 7.425L14.575 2.575C14.3917 2.39167 14.1792 2.25 13.9375 2.15C13.6958 2.05 13.4417 2 13.175 2H6C5.45 2 4.97917 2.19583 4.5875 2.5875C4.19583 2.97917 4 3.45 4 4V20C4 20.55 4.19583 21.0208 4.5875 21.4125ZM13 4V8C13 8.28333 13.0958 8.52083 13.2875 8.7125C13.4792 8.90417 13.7167 9 14 9H18V20H6V4H13ZM9 15H8V16.5C8 16.6333 7.95 16.75 7.85 16.85C7.75 16.95 7.63333 17 7.5 17C7.36667 17 7.25 16.95 7.15 16.85C7.05 16.75 7 16.6333 7 16.5V12.5C7 12.3667 7.05 12.25 7.15 12.15C7.25 12.05 7.36667 12 7.5 12H9C9.28333 12 9.52083 12.0958 9.7125 12.2875C9.90417 12.4792 10 12.7167 10 13V14C10 14.2833 9.90417 14.5208 9.7125 14.7125C9.52083 14.9042 9.28333 15 9 15ZM8 13V14H9V13H8ZM13.7125 16.7125C13.5208 16.9042 13.2833 17 13 17H11.5C11.3667 17 11.25 16.95 11.15 16.85C11.05 16.75 11 16.6333 11 16.5V12.5C11 12.3667 11.05 12.25 11.15 12.15C11.25 12.05 11.3667 12 11.5 12H13C13.2833 12 13.5208 12.0958 13.7125 12.2875C13.9042 12.4792 14 12.7167 14 13V16C14 16.2833 13.9042 16.5208 13.7125 16.7125ZM12 13V16H13V13H12ZM16.5 15H16V16.5C16 16.6333 15.95 16.75 15.85 16.85C15.75 16.95 15.6333 17 15.5 17C15.3667 17 15.25 16.95 15.15 16.85C15.05 16.75 15 16.6333 15 16.5V12.5C15 12.3667 15.05 12.25 15.15 12.15C15.25 12.05 15.3667 12 15.5 12H16.5C16.6333 12 16.75 12.05 16.85 12.15C16.95 12.25 17 12.3667 17 12.5C17 12.6333 16.95 12.75 16.85 12.85C16.75 12.95 16.6333 13 16.5 13H16V14H16.5C16.6333 14 16.75 14.05 16.85 14.15C16.95 14.25 17 14.3667 17 14.5C17 14.6333 16.95 14.75 16.85 14.85C16.75 14.95 16.6333 15 16.5 15Z"
            fill={fillColor}
          />
        </Svg>
      );
    case 'search':
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4997 16.5986C11.7997 16.5986 12.0955 16.5611 12.3872 16.4861C12.6789 16.4111 12.9497 16.2986 13.1997 16.1486L14.9497 17.8986C15.133 18.082 15.3663 18.1736 15.6497 18.1736C15.933 18.1736 16.1663 18.082 16.3497 17.8986C16.533 17.7153 16.6247 17.482 16.6247 17.1986C16.6247 16.9153 16.533 16.682 16.3497 16.4986L14.5997 14.7486C14.7497 14.4986 14.8622 14.2278 14.9372 13.9361C15.0122 13.6445 15.0497 13.3486 15.0497 13.0486C15.0497 12.082 14.708 11.2653 14.0247 10.5986C13.3413 9.93197 12.5163 9.59863 11.5497 9.59863C10.583 9.59863 9.75802 9.9403 9.07468 10.6236C8.39135 11.307 8.04968 12.132 8.04968 13.0986C8.04968 14.0653 8.38302 14.8903 9.04968 15.5736C9.71635 16.257 10.533 16.5986 11.4997 16.5986ZM11.5497 14.5986C11.133 14.5986 10.7788 14.4528 10.4872 14.1611C10.1955 13.8695 10.0497 13.5153 10.0497 13.0986C10.0497 12.682 10.1955 12.3278 10.4872 12.0361C10.7788 11.7445 11.133 11.5986 11.5497 11.5986C11.9663 11.5986 12.3205 11.7445 12.6122 12.0361C12.9038 12.3278 13.0497 12.682 13.0497 13.0986C13.0497 13.5153 12.9038 13.8695 12.6122 14.1611C12.3205 14.4528 11.9663 14.5986 11.5497 14.5986ZM6.04968 21.5986C5.49968 21.5986 5.02885 21.4028 4.63718 21.0111C4.24552 20.6195 4.04968 20.1486 4.04968 19.5986V3.59863C4.04968 3.04863 4.24552 2.5778 4.63718 2.18613C5.02885 1.79447 5.49968 1.59863 6.04968 1.59863H13.2247C13.4913 1.59863 13.7455 1.64863 13.9872 1.74863C14.2288 1.84863 14.4413 1.9903 14.6247 2.17363L19.4747 7.02363C19.658 7.20697 19.7997 7.41947 19.8997 7.66113C19.9997 7.9028 20.0497 8.15697 20.0497 8.42363V19.5986C20.0497 20.1486 19.8538 20.6195 19.4622 21.0111C19.0705 21.4028 18.5997 21.5986 18.0497 21.5986H6.04968ZM13.0497 7.59863V3.59863H6.04968V19.5986H18.0497V8.59863H14.0497C13.7663 8.59863 13.5288 8.5028 13.3372 8.31113C13.1455 8.11947 13.0497 7.88197 13.0497 7.59863Z"
            fill={fillColor}
          />
        </Svg>
      );
    case 'stack':
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.3125 16.2H10.6875C10.4219 16.2 10.1992 16.109 10.0195 15.9269C9.83984 15.7448 9.75 15.5192 9.75 15.25C9.75 14.9808 9.83984 14.7552 10.0195 14.5731C10.1992 14.391 10.4219 14.3 10.6875 14.3H16.3125C16.5781 14.3 16.8008 14.391 16.9805 14.5731C17.1602 14.7552 17.25 14.9808 17.25 15.25C17.25 15.5192 17.1602 15.7448 16.9805 15.9269C16.8008 16.109 16.5781 16.2 16.3125 16.2ZM16.3125 12.4H10.6875C10.4219 12.4 10.1992 12.309 10.0195 12.1269C9.83984 11.9448 9.75 11.7192 9.75 11.45C9.75 11.1808 9.83984 10.9552 10.0195 10.7731C10.1992 10.591 10.4219 10.5 10.6875 10.5H16.3125C16.5781 10.5 16.8008 10.591 16.9805 10.7731C17.1602 10.9552 17.25 11.1808 17.25 11.45C17.25 11.7192 17.1602 11.9448 16.9805 12.1269C16.8008 12.309 16.5781 12.4 16.3125 12.4ZM6.55078 19.4419C6.91797 19.814 7.35938 20 7.875 20H19.125C19.6406 20 20.082 19.814 20.4492 19.4419C20.8164 19.0698 21 18.6225 21 18.1V7.48375C21 7.23042 20.9531 6.98896 20.8594 6.75938C20.7656 6.52979 20.6328 6.32792 20.4609 6.15375L15.9141 1.54625C15.7422 1.37208 15.543 1.2375 15.3164 1.1425C15.0898 1.0475 14.8516 1 14.6016 1H7.875C7.35938 1 6.91797 1.18604 6.55078 1.55813C6.18359 1.93021 6 2.3775 6 2.9V18.1C6 18.6225 6.18359 19.0698 6.55078 19.4419ZM14.4375 2.9V6.7C14.4375 6.96917 14.5273 7.19479 14.707 7.37687C14.8867 7.55896 15.1094 7.65 15.375 7.65H19.125V18.1H7.875V2.9H14.4375ZM3.5875 22.4125C3.97917 22.8042 4.45 23 5 23H15C15.2833 23 15.5208 22.9042 15.7125 22.7125C15.9042 22.5208 16 22.2833 16 22C16 21.7167 15.9042 21.4792 15.7125 21.2875C15.5208 21.0958 15.2833 21 15 21H5V8C5 7.71667 4.90417 7.47917 4.7125 7.2875C4.52083 7.09583 4.28333 7 4 7C3.71667 7 3.47917 7.09583 3.2875 7.2875C3.09583 7.47917 3 7.71667 3 8V21C3 21.55 3.19583 22.0208 3.5875 22.4125Z"
            fill={fillColor}
          />
        </Svg>
      );
    case 'upload':
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6766 15.2073V18.3823C10.6766 18.6657 10.7725 18.9032 10.9641 19.0948C11.1558 19.2865 11.3933 19.3823 11.6766 19.3823C11.96 19.3823 12.1975 19.2865 12.3891 19.0948C12.5808 18.9032 12.6766 18.6657 12.6766 18.3823V15.2073L13.5766 16.1073C13.6766 16.2073 13.7891 16.2823 13.9141 16.3323C14.0391 16.3823 14.1641 16.4032 14.2891 16.3948C14.4141 16.3865 14.535 16.3573 14.6516 16.3073C14.7683 16.2573 14.8766 16.1823 14.9766 16.0823C15.16 15.8823 15.2558 15.649 15.2641 15.3823C15.2725 15.1157 15.1766 14.8823 14.9766 14.6823L12.3766 12.0823C12.2766 11.9823 12.1683 11.9115 12.0516 11.8698C11.935 11.8282 11.81 11.8073 11.6766 11.8073C11.5433 11.8073 11.4183 11.8282 11.3016 11.8698C11.185 11.9115 11.0766 11.9823 10.9766 12.0823L8.37664 14.6823C8.17664 14.8823 8.0808 15.1157 8.08914 15.3823C8.09747 15.649 8.20164 15.8823 8.40164 16.0823C8.60164 16.2657 8.83497 16.3615 9.10164 16.3698C9.3683 16.3782 9.60164 16.2823 9.80164 16.0823L10.6766 15.2073ZM5.67664 22.3823C5.12664 22.3823 4.6558 22.1865 4.26414 21.7948C3.87247 21.4032 3.67664 20.9323 3.67664 20.3823V4.38232C3.67664 3.83232 3.87247 3.36149 4.26414 2.96982C4.6558 2.57816 5.12664 2.38232 5.67664 2.38232H12.8516C13.1183 2.38232 13.3725 2.43232 13.6141 2.53232C13.8558 2.63232 14.0683 2.77399 14.2516 2.95732L19.1016 7.80732C19.285 7.99066 19.4266 8.20316 19.5266 8.44482C19.6266 8.68649 19.6766 8.94066 19.6766 9.20732V20.3823C19.6766 20.9323 19.4808 21.4032 19.0891 21.7948C18.6975 22.1865 18.2266 22.3823 17.6766 22.3823H5.67664ZM12.6766 8.38232V4.38232H5.67664V20.3823H17.6766V9.38232H13.6766C13.3933 9.38232 13.1558 9.28649 12.9641 9.09482C12.7725 8.90316 12.6766 8.66566 12.6766 8.38232Z"
            fill={fillColor}
          />
        </Svg>
      );
    case 'money':
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.5875 21.4125C4.97917 21.8042 5.45 22 6 22H18C18.55 22 19.0208 21.8042 19.4125 21.4125C19.8042 21.0208 20 20.55 20 20V8.825C20 8.55833 19.95 8.30417 19.85 8.0625C19.75 7.82083 19.6083 7.60833 19.425 7.425L14.575 2.575C14.3917 2.39167 14.1792 2.25 13.9375 2.15C13.6958 2.05 13.4417 2 13.175 2H6C5.45 2 4.97917 2.19583 4.5875 2.5875C4.19583 2.97917 4 3.45 4 4V20C4 20.55 4.19583 21.0208 4.5875 21.4125ZM13 4V8C13 8.28333 13.0958 8.52083 13.2875 8.7125C13.4792 8.90417 13.7167 9 14 9H18V20H6V4H13ZM11 18.5V18H10C9.71667 18 9.47917 17.9042 9.2875 17.7125C9.09583 17.5208 9 17.2833 9 17C9 16.7167 9.09583 16.4792 9.2875 16.2875C9.47917 16.0958 9.71667 16 10 16H13V15H10C9.71667 15 9.47917 14.9042 9.2875 14.7125C9.09583 14.5208 9 14.2833 9 14V11C9 10.7167 9.09583 10.4792 9.2875 10.2875C9.47917 10.0958 9.71667 10 10 10H11V9.5C11 9.36667 11.05 9.25 11.15 9.15C11.25 9.05 11.3667 9 11.5 9H12.5C12.6333 9 12.75 9.05 12.85 9.15C12.95 9.25 13 9.36667 13 9.5V10H14C14.2833 10 14.5208 10.0958 14.7125 10.2875C14.9042 10.4792 15 10.7167 15 11C15 11.2833 14.9042 11.5208 14.7125 11.7125C14.5208 11.9042 14.2833 12 14 12H11V13H14C14.2833 13 14.5208 13.0958 14.7125 13.2875C14.9042 13.4792 15 13.7167 15 14V17C15 17.2833 14.9042 17.5208 14.7125 17.7125C14.5208 17.9042 14.2833 18 14 18H13V18.5C13 18.6333 12.95 18.75 12.85 18.85C12.75 18.95 12.6333 19 12.5 19H11.5C11.3667 19 11.25 18.95 11.15 18.85C11.05 18.75 11 18.6333 11 18.5Z"
            fill={fillColor}
          />
        </Svg>
      );
    default:
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 8V4H6V20H18V9H14C13.7167 9 13.4792 8.90417 13.2875 8.7125C13.0958 8.52083 13 8.28333 13 8Z"
            fill={fillColor}
          />
        </Svg>
      );
  }
};
