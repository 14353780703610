import styled, { useTheme } from 'styled-components';

import { IconProps } from '..';
import { VariantType } from './types';

const Svg = styled.svg<{ isToggle?: boolean; variant?: VariantType }>`
  transition: transform 0.3s ease;
  transform: ${({ variant, isToggle }) => {
    switch (variant) {
      case 'down':
        return isToggle ? 'rotate(180deg)' : 'rotate(0deg)';
      case 'up':
        return isToggle ? 'rotate(0deg)' : 'rotate(180deg)';
      default:
        return 'rotate(0deg)';
    }
  }};

  ${({ variant, theme }) => {
    switch (variant) {
      case 'down':
        return `margin-top: -${theme.spacing[1]}`;
      case 'up':
        return `margin-top: ${theme.spacing[1]}`;
    }
  }};
`;

interface ChevronIconProps extends IconProps {
  variant?: VariantType;
  isToggle?: boolean;
}

export const ChevronIcon = ({
  height = '24',
  width = '24',
  variant = 'right',
  fillColor,
  isToggle,
}: ChevronIconProps) => {
  const theme = useTheme();
  if (!fillColor) {
    fillColor = theme.colors.primary[500];
  }
  let dPath = '';

  switch (variant) {
    case 'up':
      dPath =
        'M12 18C11.8565 18 11.722 17.9777 11.5964 17.9332C11.4709 17.8887 11.3543 17.813 11.2466 17.7061L6.29596 12.7901C6.09865 12.5941 6 12.3448 6 12.042C6 11.7392 6.09865 11.4898 6.29596 11.2939C6.49327 11.098 6.74439 11 7.04933 11C7.35426 11 7.60538 11.098 7.80269 11.2939L12 15.4618L16.1973 11.2939C16.3946 11.098 16.6457 11 16.9507 11C17.2556 11 17.5067 11.098 17.704 11.2939C17.9013 11.4898 18 11.7392 18 12.042C18 12.3448 17.9013 12.5941 17.704 12.7901L12.7534 17.7061C12.6457 17.813 12.5291 17.8887 12.4036 17.9332C12.278 17.9777 12.1435 18 12 18Z';
      break;
    case 'down':
      dPath =
        'M12 18C11.8565 18 11.722 17.9777 11.5964 17.9332C11.4709 17.8887 11.3543 17.813 11.2466 17.7061L6.29596 12.7901C6.09865 12.5941 6 12.3448 6 12.042C6 11.7392 6.09865 11.4898 6.29596 11.2939C6.49327 11.098 6.74439 11 7.04933 11C7.35426 11 7.60538 11.098 7.80269 11.2939L12 15.4618L16.1973 11.2939C16.3946 11.098 16.6457 11 16.9507 11C17.2556 11 17.5067 11.098 17.704 11.2939C17.9013 11.4898 18 11.7392 18 12.042C18 12.3448 17.9013 12.5941 17.704 12.7901L12.7534 17.7061C12.6457 17.813 12.5291 17.8887 12.4036 17.9332C12.278 17.9777 12.1435 18 12 18Z';
      break;
    case 'left':
      dPath = 'M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z';
      break;
    case 'right':
      dPath = 'M8.59 16.59L10 18L16 12L10 6L8.59 7.41L13.17 12L8.59 16.59Z';
      break;
    default:
      dPath =
        'M9.99984 6.5L8.58984 7.91L13.1698 12.5L8.58984 17.09L9.99984 18.5L15.9998 12.5L9.99984 6.5Z';
  }

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isToggle={isToggle}
      variant={variant}
    >
      <path d={dPath} fill={fillColor} />
    </Svg>
  );
};
