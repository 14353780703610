import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const OverviewIcon = ({
  height = '24',
  width = '24',
  fillColor,
}: IconProps) => {
  const theme = useTheme();
  if (!fillColor) {
    fillColor = theme.colors.primary[500];
  }
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="OverviewIcon"
    >
      <path
        d="M4 18C3.71667 18 3.47917 17.9042 3.2875 17.7125C3.09583 17.5208 3 17.2833 3 17C3 16.7167 3.09583 16.4792 3.2875 16.2875C3.47917 16.0958 3.71667 16 4 16H14C14.2833 16 14.5208 16.0958 14.7125 16.2875C14.9042 16.4792 15 16.7167 15 17C15 17.2833 14.9042 17.5208 14.7125 17.7125C14.5208 17.9042 14.2833 18 14 18H4ZM4 13C3.71667 13 3.47917 12.9042 3.2875 12.7125C3.09583 12.5208 3 12.2833 3 12C3 11.7167 3.09583 11.4792 3.2875 11.2875C3.47917 11.0958 3.71667 11 4 11H20C20.2833 11 20.5208 11.0958 20.7125 11.2875C20.9042 11.4792 21 11.7167 21 12C21 12.2833 20.9042 12.5208 20.7125 12.7125C20.5208 12.9042 20.2833 13 20 13H4ZM4 8C3.71667 8 3.47917 7.90417 3.2875 7.7125C3.09583 7.52083 3 7.28333 3 7C3 6.71667 3.09583 6.47917 3.2875 6.2875C3.47917 6.09583 3.71667 6 4 6H20C20.2833 6 20.5208 6.09583 20.7125 6.2875C20.9042 6.47917 21 6.71667 21 7C21 7.28333 20.9042 7.52083 20.7125 7.7125C20.5208 7.90417 20.2833 8 20 8H4Z"
        fill={fillColor}
      />
    </Svg>
  );
};
