import theme from 'stories/theme';

import { IconProps } from '..';
import { Svg } from '../styles';

export const PersonIcon = ({
  height = '24',
  width = '24',
  fillColor = theme.colors.primary[500],
}: IconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1167_6698)">
      <path
        d="M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 16C14.7 16 17.8 17.29 18 18H6C6.23 17.28 9.31 16 12 16ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_1167_6698">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
