import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const FolderIcon = ({
  height = '24px',
  width = '24px',
  strokeColor: initStrokeColor,
}: IconProps) => {
  const theme = useTheme();
  const strokeColor = initStrokeColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5858 13.4142L23.1716 14H24H40C40.5653 14 40.9995 14.178 41.4108 14.5892C41.822 15.0005 42 15.4346 42 16V36C42 36.5654 41.822 36.9995 41.4108 37.4108C40.9995 37.822 40.5653 38 40 38H8C7.43465 38 7.00047 37.822 6.58921 37.4108C6.17796 36.9995 6 36.5654 6 36V12C6 11.4346 6.17796 11.0005 6.58921 10.5892C7.00047 10.178 7.43465 10 8 10H18.35C18.6262 10 18.8742 10.0504 19.1103 10.148C19.3529 10.2484 19.5567 10.3851 19.7358 10.5642L22.5858 13.4142Z"
        stroke={strokeColor}
        stroke-width="4"
      />
    </Svg>
  );
};
