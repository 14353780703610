import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const NotesIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.90234 18.9922H14.9023V14.9922C14.9023 14.7089 14.9982 14.4714 15.1898 14.2797C15.3815 14.088 15.619 13.9922 15.9023 13.9922H19.9023V4.99219H5.90234V18.9922ZM5.90234 20.9922C5.35234 20.9922 4.88151 20.7964 4.48984 20.4047C4.09818 20.013 3.90234 19.5422 3.90234 18.9922V4.99219C3.90234 4.44219 4.09818 3.97135 4.48984 3.57969C4.88151 3.18802 5.35234 2.99219 5.90234 2.99219H19.9023C20.4523 2.99219 20.9232 3.18802 21.3148 3.57969C21.7065 3.97135 21.9023 4.44219 21.9023 4.99219V14.1672C21.9023 14.4339 21.8523 14.688 21.7523 14.9297C21.6523 15.1714 21.5107 15.3839 21.3273 15.5672L16.4773 20.4172C16.294 20.6005 16.0815 20.7422 15.8398 20.8422C15.5982 20.9422 15.344 20.9922 15.0773 20.9922H5.90234ZM11.9023 13.9922H8.90234C8.61901 13.9922 8.38151 13.8964 8.18984 13.7047C7.99818 13.513 7.90234 13.2755 7.90234 12.9922C7.90234 12.7089 7.99818 12.4714 8.18984 12.2797C8.38151 12.088 8.61901 11.9922 8.90234 11.9922H11.9023C12.1857 11.9922 12.4232 12.088 12.6148 12.2797C12.8065 12.4714 12.9023 12.7089 12.9023 12.9922C12.9023 13.2755 12.8065 13.513 12.6148 13.7047C12.4232 13.8964 12.1857 13.9922 11.9023 13.9922ZM16.9023 9.99219H8.90234C8.61901 9.99219 8.38151 9.89635 8.18984 9.70469C7.99818 9.51302 7.90234 9.27552 7.90234 8.99219C7.90234 8.70885 7.99818 8.47135 8.18984 8.27969C8.38151 8.08802 8.61901 7.99219 8.90234 7.99219H16.9023C17.1857 7.99219 17.4232 8.08802 17.6148 8.27969C17.8065 8.47135 17.9023 8.70885 17.9023 8.99219C17.9023 9.27552 17.8065 9.51302 17.6148 9.70469C17.4232 9.89635 17.1857 9.99219 16.9023 9.99219Z"
        fill={fillColor}
      />
    </Svg>
  );
};
